<template>
    <div>
      <TopNav />
      <router-view></router-view>
    </div>
</template>

<script>
import TopNav from '@/views/wallet/common/TopNav.vue'
export default {
  components: {
    TopNav
  },

}
</script>

<style>

</style>