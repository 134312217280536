<template>
  <div class="overflow_auto">
    <div class="color pt-5" :style="`background-color:${$store.state.theme_color}`" v-if="$store.state.main_style==='total_assets'">
      <div class="box_content text-center">
          <div class="text-center">
            <div class="mb-1 mt-5 d-flex align-items-center justify-content-center text-white opacity-80">
              <i class="fas fa-coin small me-2"></i>
              <div class="small">Total assets</div>
            </div>
            <div class="mb-4 text-white">
              <h3>{{ total_usd }} USD</h3>
            </div>
          </div>
          <div class="progress " v-if="coinTestList.length>0">
            <div class="progress-bar d-flex flex-column align-items-center text-dark" 
            role="progressbar" 
            :style="{ width: calculateWidth(item.usd_balance) + '%',  background: 'rgba(255, 255, 255, ' + calculateOpacity(index) + ')' }" 
            v-for="(item,index) in coinTestList" :key="index">
              <b style="line-height:1;" v-if="item.usd_balance ==0">{{ 0+ '%' }}</b>
              <b style="line-height:1;" v-if="item.usd_balance !=0">{{ Math.round(calculateWidth(item.usd_balance)) + '%' }}</b>
              <span class="text-truncate w-100 px-2 mt-2" style="line-height:1;">{{item.name}}</span>
            </div>
          </div>
      </div>
    </div>
    <div class="pt-5" v-else>
      <div class="box_content text-center">
        <div class="pt-5">
          <img :src="$store.state.logo_img == '' ? require('@/assets/img/logo/logo_black.svg') : $store.state.logo_img" class="mx-auto top_logo" alt="" height="100">
        </div>
      </div>
    </div>
    <div class="coin_wrap">
      <div class="banner" @click="bannerClick" v-if="$store.state.top_banner">
        <span>상단 배너 (광고 영역)</span>
      </div>
      <ul class="ps-0 coin_list">
        
        <!-- <li class="float_box mb-3" @click="$router.push({name: 'Staking'})">
          <div class="box_title">  
            <h6 class="mb-0"><i class="far fa-chart-line me-2"></i>STAKING</h6>
            <div class="status">
              <span>{{$t("staking.11")}} {{staking.total}}{{$t("dashboard.3")}} {{$t("staking.10")}}</span>
              <i class="fas fa-chevron-right"></i>
            </div>
          </div>
          <div class="box_content w-100">
              <div class="d-flex justify-content-between align-items-center">
                  <label style="color: #541792;">{{$t('staking.17')}} {{$t("staking.12")}}</label>
                  <p>{{yet.begin}} {{$t("dashboard.3")}}</p>
              </div>
              <div class="gauge_box my-2">
                  <div class="gauge" :style="'width :'+ (((yet.begin/yet.total)*100)|0) +'%;'"></div>
              </div>
              <div class="d-flex justify-content-between align-items-center">
                  <label>{{$t("staking.13")}}</label>
                  <p>{{staking.ing}} {{$t("dashboard.3")}}</p>
              </div>
              <div class="d-flex justify-content-between align-items-center">
                  <label>{{$t("staking.14")}}</label>
                  <p>{{staking.confirm}} {{$t("dashboard.3")}}</p>
              </div>
              <div class="d-flex justify-content-between align-items-center">
                  <label>{{$t("staking.15")}}</label>
                  <p>{{staking.succ}} {{$t("dashboard.3")}}</p>
              </div>
          </div>
        </li> -->
        <!-- <li class="float_box p-4" style="background-color:#45445e; background:linear-gradient(135deg,rgb(44 130 203),#3756da,#632cb7);" @click="nftMove()">
            <div class="d-flex flex-grow-1 align-items-center">
              <div class="text-white">
                <p class="h4">BISKIT NFT</p>
              </div>
            </div>
        </li> -->
        <!-- <hr class="cutting_line"> -->
        
        <div class="search_wrap d-flex justify-content-between mt-4 mb-3">
          <div class="search_input" style="width: calc(100% - 45px);">
            <i class="far fa-search position-absolute"></i>
            <input type="search" v-model="search" class="d-flex p-1" placeholder="Search Coin">
          </div>
          <div class="d-flex align-items-center justify-content-center set_btn" @click="coinSettingLocation">
            <i class="fas fa-cog"></i>
          </div>
        </div>

        <li class="float_box p-4" v-for="(coin, index) in postList" :key="index" >
            <div class="info mb-3">
              <span class="icon me-3">
                <img :src="coin.img" alt="icon" style="width: 100%;">
              </span>
              <span>{{coin.name}}</span>
              <span class="lock" v-if="coin.lock_balance > 0"><i class="far fa-solid fa-lock"></i> LOCK Balance : {{coin.lock_balance}} </span>
            </div>
            <div class="retained d-flex flex-column">
              <b>{{coin.balance}} {{coin.symbol}}</b>
              <span class="main_txt d-flex align-items-center justify-content-between" :style="`color:${$store.state.theme_color} !important`">
                <span>= {{usdCalc(coin.symbol, coin.balance)}} USD</span>
                <span>{{coin.symbol}} 1 = {{rateCheck(coin.symbol)}} USD</span>
              </span> 
            </div>
        </li>

        <hr class="cutting_line">

        <li class="float_box notice_box d-block">
          <div class="box_title py-3 px-4" :class="{'text-white':$store.state.notice_type==='color'}" :style="`background-color:${$store.state.notice_type==='color'?$store.state.theme_color:'initial'} !important`">   
            <h6 class="mb-0">공지사항</h6>
            <div class="status">
              <span><router-link class="small" :class="{'text-white':$store.state.notice_type==='color'}" to="#">
                더보기
              </router-link></span>
            </div>
          </div>
          <ul class="board_tit_box px-4 py-3" :class="{'pt-0':$store.state.notice_type==='simple'}">
            <li v-for="(item,index) in notice_list.slice(0,1)" :key="index" class="py-2">
              <!-- <router-link :to="`/page/notice/${item.no}`"> -->
              <router-link :to="`#`">
                  <div class="d-flex flex-column w-80">
                    <p class="tit">{{item.title}}</p>
                    <p class="date">{{item.date}}</p>
                  </div>
                  <i class="far fa-chevron-right ps-3 flex-shrink-0"></i>
              </router-link>
            </li>
            <li v-if="notice_list.length == 0" class="pb-0">
              <div class="empty_data text-center">
                <small class="pale_txt">공지사항이 없습니다.</small>
              </div>
            </li>
          </ul>
        </li>
      </ul>
      <div class="footer mt-5">
          ©{{new Date().getFullYear()}} CO,. LTD. All rights reserved.<br>
          <!-- CEO {{$store.state.FOOTER.CEO}}<br> -->
          <!-- Company Address : {{$store.state.FOOTER.ADDRESS}} -->
          https://wallet.com
      </div>
    </div>
    
    <div id="Nav" class="position-fixed bottom-0 w-100 bg-body" v-if="$store.state.bottom_nav">
          <div>
              <ul class="p-0 mb-0 d-flex justify-content-around align-items-center">
                  <li><router-link to="#">
                      <div class="lh-1 fs-5 mb-1"><i class="fas fa-home-alt"></i>
                      </div>
                      <span class="fs-px-10">홈</span>
                  </router-link></li>
                  <li><router-link to="#">
                      <div class="lh-1 fs-5 mb-1">
                          <i class="fal fa-bars"></i>
                      </div>
                      <span class="fs-px-10">메뉴</span>
                  </router-link></li>
                  <li><router-link to="#">
                      <div class="lh-1 fs-5 mb-1">
                          <i class="fal fa-shopping-basket"></i>
                      </div>
                      <span class="fs-px-10">상점</span>
                  </router-link></li>
                  <li><router-link to="#">
                      <div class="lh-1 fs-5 mb-1">
                          <i class="fal fa-user-circle"></i>
                      </div>
                      <span class="fs-px-10">마이페이지</span>
                  </router-link></li>
              </ul>
          </div>
      </div>
    <div>
      <div class="popup_box" :class="{on : index == popup.length-1}" v-for="(item, index) in popup.slice().reverse()" :key="index">
        <div class="popup" v-if="item.active">
            <div class="dimm" @click="item.active = false, addClassOn(index)"></div>
            <div class="content_box">
              <p class="top_tit">{{item.title}}</p>
              <i class="fal fa-times close_icon" @click="item.active = false, addClassOn(index)"></i>
              <div class="content">
                <div class="tit_box">
                  <h5>NOTICE</h5>
                </div>
                <div class="txt_box">
                  {{item.description}}
                </div>
              </div>
              <span class="nomore_btn" @click="PopupNon(item),addClassOn(index)"><i class="fal fa-check-circle"></i>$%@#$@#$</span>
            </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {

  data(){
    return{
      coinTestList:[
        {
            name: 'btc',
            usd_balance: 38,
        },
        {
            name: 'eth',
            usd_balance: 62,
        },
      ],
      totalBalnce: 0,
      coinList:[
        {
            img: require('@/assets/img/icon/color/btc.svg'),
            name: 'Bitcoin',
            symbol: 'BTC',
            lock_balance: 0,
            balance: 0.38,
        },
        {
            img: require('@/assets/img/icon/color/eth.svg'),
            name: 'Ethereum',
            symbol: 'ETH',
            lock_balance: 0,
            balance: 15.164,
        },
      ],
      coinBalance: [],
      search: '',

      staking : {
        total: 0,
        confirm: 0,
        ing: 0,
        begin: 0,
        done: 0,
        succ: 0
      },
      yet:{
        total: 0,
        begin: 0
      },

      popup: [],
      popupNon: [],
      total_usd : '50,379.26',
      rate: [],
      notice_list: [
        {
            title: "Wallet이 오픈되었습니다.",
            date: "2023-12-15"
        }
      ],
      nft :{balance:1}
    }
  },
  computed: {
    postList() {
      let list = [];
      
      for( let i=0; i<this.coinList.length; i++ ){
        if( this.coinList[i].symbol == 'EVOL'){
          list.unshift(this.coinList[i])
        }else{
          list.push(this.coinList[i]);
        }
      }

      return list.filter(post => {
        return post.name.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  },
  methods: {
    calculateOpacity(index) {
      // 데이터 개수에 따라 각 항목의 opacity를 계산하여 반환합니다.
      
      const totalItems = this.coinTestList.length;

      return (totalItems - index) / totalItems;
    },

    calculateWidth(balance) {
      // 전체 밸런스의 합을 구합니다.

      const totalBalance = this.coinTestList.reduce((sum, item) => sum + item.usd_balance , 0);

      if(totalBalance ==0){
        this.total_usd = 0;
        const totalItems = this.coinTestList.length;
        let per = 100 / totalItems;
        return per;
      }else{
        const str = String(totalBalance);

        // this.total_usd = str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');

        // 밸런스의 비율을 계산하여 퍼센티지로 반환합니다.
        return (balance / totalBalance) * 100;
      }
      
    },
    CoinUsdBalance(symbol,index){
      const balance = this.coinList[index].balance;

      const rate = this.rate;
      for(var i=0; i<rate.length; i++){
        if(symbol == rate[i].symbol) {
          
          
          this.coinTestList[index].usd_balance = parseFloat((parseFloat(balance) * parseFloat(rate[i].usd)).toFixed(2))
          const str = String((parseFloat(balance) * parseFloat(rate[i].usd)).toFixed(2));
          const price = str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
          
          return price; 
          
        }
      }
    },
    rateCheck(symbol){
      const rate = this.rate;
      for(var i=0; i<rate.length; i++){
        if(symbol == rate[i].symbol) return rate[i].usd;
      } 
    },
    usdCalc(symbol, value){
      const rate = this.rate;
      for(var i=0; i<rate.length; i++){
        if(symbol == rate[i].symbol) { const str = String((parseFloat(value) * parseFloat(rate[i].usd)).toFixed(2));
          const price = str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,'); return price; 
        }
      }
    },
    addClassOn(index){
      var popupBox = document.querySelectorAll(".popup_box")
      if( popupBox[index-1] ){
        popupBox[index].classList.remove("on")
        popupBox[index-1].classList.add("on")
      }else{
        popupBox[index].classList.remove("on")
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar { 
  display: none !important; // 윈도우 크롬 등
}
.fs-px-10{
  font-size: 10px;
}
#Nav{
    box-shadow: 0 0px 20px rgba(125, 125, 125, 0.1);
    // max-width: 768px;
    z-index: 99;
    // max-width: 100%;

    ul{
        height: 60px;

        li{
            width: 25%;
            line-height: 1;
            text-align: center;

            a{
                opacity: 0.4;
                color: #000;
            }

            a.router-link-active{
                opacity: 1;
            }
        }
    }
    
}
.coin_wrap{
  background-color:#F5F6FA;
  padding: 1em;
  min-height: calc(100vh - 250px);
}
.float_box{
  position: relative;
  width: 100%;
  border-radius: 12px;
  background-color: #fff;
  min-height: 100px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  box-shadow: 0px 3px 2px 0 rgba(0, 0, 0, 0.05);
  margin-bottom: 16px;
}

.color{
  box-shadow: 0px 5px 10px 3px #00000008;
  position: relative;
  min-height: 200px;
  /* padding-top: 1em; */
  background: #4b67f9;
  /* background: linear-gradient(to bottom, #9070af, #00ada9 80%);
  background: linear-gradient(to top, #24243e 5%, #302b63 75%,rgb(15 12 41 / 0.6)); */
  /* background: linear-gradient(to top, #5b87ad, #8774af); */
  border-radius: 0 0 30px 30px;
}
.wallet{
  background: #4B67F9;  /* fallback for old browsers */
  // background: -webkit-linear-gradient(to right, #363795, #4B67F9);
  // background: linear-gradient(to right, #363795, #4B67F9); 
  color: #fff;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 130px;
}
.wallet p{
  font-weight: 300;
  /* font-size: 14px; */
}
.wallet h2{
  font-weight: 700;
}
.box_title{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  font-size: 13px;
  border-bottom: 1px solid #eee;
}
.box_title h6{
  font-weight: 600;
}
.box_title h6 i{
  background: #4B67F9;
  border-radius: 6px;
  color: #fff;
  padding: 8px;
  font-size: 1em;
}
/* .box_content{
  padding: 1em 1em;
  box-sizing: border-box;
}
.box_content p{
  font-weight: 500;
  margin-bottom: 00px;

  color: #a9c1da;
  font-size: 12px;
  width: calc(100% - 80px);
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: normal;
}
.box_content label{
  font-size: 13px;
  margin-bottom: 0;
  color: #bbb;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  text-align: center;
  padding: 4px 10px;
  color: #fff;
  margin-right: auto;
} */

.box_content{
    padding: 1em 1em;
    box-sizing: border-box;
}
.box_content p{
    font-weight: 500;
    margin-bottom: 5px;
}
.box_content label{
    font-size: 12px;
    margin-bottom: 0;
    color: #bbb;
}

.box_content .fa-copy{
    font-size: 12px;
    color: #87c3ff;
    margin-left: 10px;
}
.status span{
  color: #4B67F9;
  font-weight: 600;
  font-size: 15px;
}

.status i {
  /* color: #988cca; */
  margin-left: 10px;
}

.notice_box .status span a{
  color: #171c61;
}
.notice_box .box_title, .board_tit_box{
  width : 100%;
}
.notice_box .box_title, .board_tit_box li{
  border-bottom: 0;
}
.notice_box .box_title, .board_tit_box i{
  font-size: 11px;
}
.notice_box .board_tit_box .tit{
  /* font-size: 14px; */
  font-size: 0.9em;
  font-weight: 400;
}
.notice_box .board_tit_box .date{
  /* font-size: 12px; */
  font-size: 0.8em;
}

.coin_list{
  /* transform: translateY(-50px); */
}
.coin_list .info{
  width: 100%;
}
.coin_list .icon{
  position: absolute;
  right: 5px;
  top: 25px;
  width: 32px;
  height: 32px;
}
.coin_list .icon img{
  height: 32px;
  object-fit: contain;
}
.coin_list .retained{
  width: 100%;
}
.coin_list .retained span{
  font-size: 12px;
}
  .popup{
    width: 100%;
    height: 100vh;
    min-height: 500px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 150;
    overflow-y: auto;
  }
  .popup .dimm{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .popup_box{
    /* display: block; */
    display: none;
  }
  .popup_box.on{
    display: block;
  }
  .popup_box:first-of-type .dimm{
    background-color: rgba(0, 0, 0, 0.5);
  }
  .popup .content_box{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    height: 500px;
    /* border: 1px solid #fff */
  }
  .popup .content{
    position: relative;
    width: 100%;
    height: calc(500px - 90px);
    margin-top: 40px;
    /* background-color: #495da8; */
    background-color: #fff;
    /* border-radius: 10px; */
    overflow: hidden;
    
  }
  .popup .close_icon{
    color: #fff;
    font-size: 25px;
    position: absolute;
    top: 10px;
    right: 10px;

  }
  .popup .nomore_btn{
    color: #fff;
    display: inline-block;
    transform: translate(10px,10px);
    font-size: 15px;
  }
  .popup .tit_box{
    /* background-color: #495da8; */
    color: #495da8;
    height: 70px;
    text-align: center;
    font-family: 'Fugaz One', sans-serif;
    font-weight: 600;
    letter-spacing: 2px;
  }
  .popup .tit_box h5{
    font-size: 30px;
    font-weight: normal;
    line-height: 80px;
    text-overflow: ellipsis;
  }
  .popup .txt_box{
    text-align: center;
    word-break: break-all;
    padding: 10px 25px;
    overflow-y: auto;
    height: calc(100% - 100px);
    white-space: pre-line;
    font-size: 15px;
    line-height: 1;
  }

  .popup .top_tit{
    font-size: 14px;
    position: absolute;
    top: 8px;
    left: 0;
    color: #fff;
    font-weight: normal;
    max-width: calc(100% - 30px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
    margin: 0;
    display: inline-block;
    padding: 2px 10px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
  }
  .total_balance{
    font-size: 2.5em;
  } 
  .banner{
    position: relative;
    width: 100%;
    border-radius: 12px;
    background-size: 300%;
    background: #c6c6c6;
    background: linear-gradient(to left, #c6c6c6, #cacaca);
    min-height: 90px;
    overflow: hidden;
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    box-shadow: 0px 3px 2px 0 rgba(0, 0, 0, 0.05);
    // transform: translateY(-50px);
    margin-bottom: 1em;
    text-align: center;
    justify-content: center;
    font-weight: 600;
    text-shadow: 2px 2px 0px #aaaaaa;
    cursor: pointer;
  }
  .banner span{
    position: relative;
    z-index: 2;
  }
  .banner::after{
    content: "";
    display: block;
    background: linear-gradient(90deg,#21245400 0%,#d5d5d5 10%,#21245400 20%);
    animation: gradient-move 3s infinite ease;
    animation-delay: 2s;
    transition: background-position;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }

  .search_input{
    /* background-color: #ddf0fb; */
    /* background-color: #bbdbec; */
    /* border: 1px solid #ddd; */
    border-bottom: 1px solid #ddd;
    /* border-radius: 11px; */
    /* background-color: #fff; */
    /* border: none; */
    position: relative;
    /* box-shadow: 0px 3px 2px 0 rgb(0 0 0 / 5%); */
  }
  .search_input>i{
    font-size: 14px;
    transform: translate(50%,-50%);
    left: 4px;
    top: 50%;
  }
  .search_input input{
    border: none;
    background-color: transparent !important;
    outline: none;
    width: calc(100% - 30px);
    margin-left: 30px;
  }
  .set_btn{
    /* border: 2px solid #c1c1c1; */
    border-radius: 50%;
    width: 35px;
    height: 35px;
    /* background-color: #fff; */

    
    /* box-shadow: 0px 3px 2px 0 rgb(0 0 0 / 20%); */
    color: #787878;
  }
  .set_btn>i{
    font-size: 14px;
    padding: 5px;
    margin-right: 1px;
    margin-top: 1px;
  }
@keyframes gradient-move{
  0%{
    /* background-position: 0; */
    left: -20%;
  }
  100%{
    /* background-position: 100px; */
    left: 100%;
  }
}

.lock {
  display: block;
  font-size: 13px;
  font-weight: 500;
  color: #ff7b7b;
}

.drop_shadow{
  filter: drop-shadow(2px 4px 6px rgba(0,0,0,0.3));
}

.go_btn{
  border-color: #bfbddb!important;
  color: #bfbddb;
  font-size: 12px;
}

.w-90{
  width: 90%;
}

.bg-primary,.badge-primary{
  background-color: #4B67F9 !important;
}
.progress{
  height: 50px;
  border-radius: 16px;
  background-color: transparent !important;
}

.opacity-80{
  opacity: 0.8;
}
.w-80{
    width: 80%
}
.top_logo{
  max-width: 250px;
}
</style>